<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            v-if="langInfo"
            :src="$i18n.locale == 'fa' ? fa_auth_banner : en_auth_banner"
            alt="Login"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 px-lg-5 pt-lg-2"
      >
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <AlertForDeprecate full/>
          <b-alert
            v-height-fade
            show
            dismissible
            fade
            class="mb-0"
            variant="success"
            v-if="sendEmail == true"
          >
            <div class="alert-body">
              <span>{{ $t("Pages.Auth.Title.activation_email") }}</span>
            </div>
          </b-alert>

          <b-card-title title-tag="h3" class="font-weight-bold mb-1 mt-1">
            {{ $t("Pages.Auth.Title.wellcome_msg") }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t("Pages.Auth.Title.first_log") }}
          </b-card-text>

          <!-- form -->
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- email -->
            <b-form-group
              :label="$t('Pages.Auth.Label.email_label')"
              label-for="login-email"
            >
              <b-form-input
                id="login-email"
                type="text"
                v-model="userEmail"
                name="login-email"
                placeholder=""
              />
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">{{
                  $t("Pages.Auth.Label.password_label")
                }}</label>
              </div>
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="login-password"
                  v-model="password"
                  class="form-control-merge"
                  :type="showPassword ? 'text' : 'password'"
                  name="login-password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="showPassword ? 'EyeOffIcon' : 'EyeIcon'"
                    @click="togglePassword"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <!-- submit buttons -->
            <b-button type="submit" variant="primary" block @click="login">
              {{ $t("Pages.Auth.Button.login_btn") }}
            </b-button>
          </b-form>

          <b-card-text class="text-center mt-2">
            <span>{{ $t("Pages.Auth.Title.no_account") }} </span>
            <b-link :to="{ name: 'signup' }">
              <span>&nbsp;{{ $t("Pages.Auth.Title.create_account") }}</span>
            </b-link>
          </b-card-text>

          <b-card-text class="text-center mt-2">
            <span>{{ $t("Pages.Auth.Title.forget_pass") }} </span>
            <b-link :to="{ name: 'forgetPassword' }">
              <span>&nbsp;{{ $t("Pages.Auth.Title.recovery_pass") }}</span>
            </b-link>
          </b-card-text>

          <div class="auth-footer-btn d-flex justify-content-center py-1">
            <div class="google-btn" v-on:click="loginGoogle()">
              <div class="google-icon-wrapper">
                <img
                  class="google-icon"
                  src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                />
              </div>
              <p class="btn-text text-center w-100">
                <b class="ml-1">{{ $t("Pages.Auth.Button.google_login") }}</b>
              </p>
            </div>
          </div>

          <!-- <b-card-text class="mb-0 text-center">
            <b-img
              :src="
                $store.state.appConfig.layout.skin == 'dark'
                  ? require('@/assets/images/banner/ytc-small-light.png')
                  : require('@/assets/images/banner/ytc-small.png')
              "
              alt="logo"
              height="80"
            />
          </b-card-text> -->
          <!-- /////change lang section -->
          <b-card-text class="mb-0 text-center">
            <Locale />
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { langInfoDao } from "@/daos/languages/langInfoDao";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AlertForDeprecate from "@/components/AlertForDeprecate.vue";
export default {
  data() {
    return {
      showPassword: false,
      status: "",
      password: "",
      userEmail: "",
      sendEmail: false,
      langInfo: null,
      en_auth_banner: null,
      fa_auth_banner: null,
    };
  },
  async beforeCreate() {
    try {
      const resp = await this.$http.get("/api/check_login");
      const status = resp.data.status;
      if (status !== 401) {
        const locale = resp.data.user.selected_language_key;
        localStorage.setItem("language", locale);
        const isRTL = locale === "fa";
        document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
        this.$i18n.locale = locale;
        this.$router.push("/").catch(() => {});
      }
    } catch (err) {
      // if user not login......
      this.$VueCookies.remove("mastertube");
    }
  },
  async created() {
    this.getAllBanners();
  },
  components: {
      AlertForDeprecate,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    Locale,
  },
  directives: {
    Ripple,
  },
  computed: {
    validateForm() {
      return this.userEmail !== "" && this.password !== "";
    },
  },

  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    loginGoogle() {
      this.makeToast(
        this.$t("Base.Alert.Successful"),
        this.$t("Base.Alert.please_wait"),
        "success"
      );
      this.$http
        .get("/api/login/google")
        .then((response) => {
          window.location.href = response.data;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$VueCookies.remove("mastertube");
            this.$router.push("/login").catch(() => {});
          }
        });
    },
    login() {
      if (this.userEmail != "" && this.password != "") {
        const payload = {
          email: this.userEmail,
          password: this.password,
          language_key: this.$i18n.locale,
        };
        this.$http
          .post(`/api/login`, payload)
          .then((resp) => {
            if (resp.status == 200) {
              this.sendEmail = false;
              this.user = resp.data.user;
              this.token = resp.data.token;
              this.$VueCookies.set("mastertube", resp.data.token);
              let searchParams = new URLSearchParams(window.location.search);
              if (searchParams.has("redirect")) {
                this.$router.push({ name: searchParams.get("redirect") });
              } else window.open(process.env.VUE_APP_Url, "_self");
            } else {
              this.makeToast(
                this.$t("Base.Alert.Error"),
                this.$t("Base.Alert.username_pass_incorrect"),
                "danger"
              );
              this.$VueCookies.remove("mastertube");
            }
          })
          .catch((error) => {
            if (error.response.status == 502) {
              this.sendEmail = true;
              this.makeToast(
                this.$t("Base.Alert.Successful"),
                this.$t("Base.Alert.activation_email_send"),
                "success"
              );
              this.$VueCookies.remove("mastertube");
            } else {
              this.sendEmail = false;
              this.makeToast(
                this.$t("Base.Alert.Error"),
                this.$t("Base.Alert.username_pass_incorrect"),
                "danger"
              );
              this.$VueCookies.remove("mastertube");
            }
          });
      } else {
        this.makeToast(
          this.$t("Base.Alert.Error"),
          this.$t("Base.Alert.info_incomplete"),
          "danger"
        );
      }
    },
    async getAllBanners() {
      const langInfo= await langInfoDao.getLangInfo()
      this.langInfo=langInfo;
      const en_banners = langInfo.en.images;
      const fa_banners = langInfo.fa.images;
      this.en_auth_banner = en_banners.auth_banner;
      this.fa_auth_banner = fa_banners.auth_banner;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
